import React, { useState } from 'react'
import { ModalDescription } from '../Text'
import Input from 'shared/components/Input'
import { isValidPassword } from 'shared/validators'
import { useModalContext } from 'shared/context/ModalProvider'
import { useNotificationContext } from 'shared/context/NotificationProvider'
import Modal from '../Modal'
import { Auth } from 'aws-amplify'

const ChangePasswordModal = (props) => {
  const { closeModal } = useModalContext()
  const { sendSuccessNotification, sendErrorNotification } = useNotificationContext()
  const initialFieldState = { currentPassword: '', newPassword: '', confirmPassword: '' }
  const [loading, setLoading] = useState(false)
  const [field, setField] = useState(initialFieldState)
  const [fieldError, setFieldError] = useState(initialFieldState)

  const updateField = (event) => {
    setField({ ...field, [event.target.name]: event.target.value })
  }

  const validateFieldOnKeyUp = (event) => {
    if (fieldError[event.target.name]) {
      validateField(event)
    }
  }

  const validateField = (event) => {
    if (event.target.value === '') {
      setFieldError({ ...fieldError, [event.target.name]: '' })
    } else if (!fieldErrorValidators[event.target.name].validator(event.target.value)) {
      setFieldError({ ...fieldError, [event.target.name]: fieldErrorValidators[event.target.name].error })
    } else {
      setFieldError({ ...fieldError, [event.target.name]: '' })
    }
  }

  const fieldErrorValidators = {
    newPassword: {
      error: 'Password must be at least 8 characters and include an uppercase letter, a number, and a special character',
      validator: isValidPassword
    },
    confirmPassword: {
      error: 'Invalid password, both passwords must match',
      validator: (confirmPassword) => field.newPassword === confirmPassword
    }
  }

  const isFormValid = () => {
    const fieldErrors = Object.keys(fieldError)
    const fields = Object.keys(field)
    const isWithoutFieldErrors = fieldErrors.every(fieldName => fieldError[fieldName] === '') // eslint-disable-line security/detect-object-injection
    const hasAllFieldsFilled = fields.every(fieldName => field[fieldName] !== '') // eslint-disable-line security/detect-object-injection
    return hasAllFieldsFilled && isWithoutFieldErrors
  }

  const changePassword = async () => {
    setLoading(true)

    try {
      const user = await Auth.currentAuthenticatedUser()
      await Auth.changePassword(user, field.currentPassword, field.newPassword)
      sendSuccessNotification({ title: 'Password changed successfully!' })
      closeModal()
    } catch (error) {
      if (error.code === 'LimitExceededException') {
        sendErrorNotification({ title: 'Password reset limit reached. Please try again in a couple hours.' })
      } else sendErrorNotification({ title: 'Password change failed. Please try changing again.' })
      closeModal()
    }
  }

  const modalDialogue = {
    primary: {
      onClick: changePassword,
      disabled: !isFormValid()
    },
    secondary: {
      onClick: closeModal
    }
  }

  if (loading) {
    return null
  }

  return (
    <Modal dialogue={modalDialogue} onClose={closeModal} title={'Change Password'} {...props}>
      <ModalDescription>
        Make sure your new password is at least 8 characters long and includes an uppercase letter,
        a number, and a special character.
      </ModalDescription>
      <Input
        data-testid="current-password"
        label="Current Password"
        name="currentPassword"
        onChange={updateField}
        placeholder="Enter your current password"
        type="password"
        value={field.currentPassword} />
      <Input
        data-testid="new-password"
        error={fieldError.newPassword}
        label="New Password"
        name="newPassword"
        onBlur={validateField}
        onChange={updateField}
        onKeyUp={validateFieldOnKeyUp}
        placeholder="Min. 8 chars with uppercase, number & symbol"
        type="password"
        value={field.newPassword} />
      <Input
        data-testid="confirm-new-password"
        error={fieldError.confirmPassword}
        label="Confirm New Password"
        name="confirmPassword"
        onBlur={validateField}
        onChange={updateField}
        onKeyUp={validateFieldOnKeyUp}
        placeholder="Min. 8 chars with uppercase, number & symbol"
        type="password"
        value={field.confirmPassword} />
    </Modal>
  )
}

export default ChangePasswordModal
