import React, { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import Icon from '@mdi/react'
import { mdiCloseCircle } from '@mdi/js'
import { DragAndDropWrapper, DropFileArea, PlaceholderText, File, FileTitle } from './index.styles'
import { B1 } from '../Text/index'
import { colors } from 'shared/colors'

function FileUpload (props) {
  const setFile = useCallback(acceptedFiles => {
    if (acceptedFiles.length > 0) {
      props.setFile(acceptedFiles[0])
    }
  }, [props])

  const onDropRejected = useCallback(() => {
    if (props.onError) {
      props.onError('Upload must be an audio file')
    }
  }, [props])

  const clearFile = () => {
    props.setFile(null)
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: setFile,
    onDropRejected,
    accept: props.acceptedFileTypes,
    maxFiles: 1,
    validator: file => {
      if (!file.type.startsWith('audio/')) {
        return {
          code: 'file-invalid-type',
          message: 'Please upload an audio file'
        }
      }
      return null
    }
  })

  const renderFile = () => {
    return (
      <File data-testid="file">
        <FileTitle>{props.file.path}</FileTitle>
        <Icon color={colors.RED} data-testid="clearFile" onClick={clearFile} path={mdiCloseCircle} size={1} />
      </File>
    )
  }

  const renderDropArea = () => {
    return (
      <DropFileArea isDragActive={isDragActive}>
        <input {...getInputProps()} />
        {isDragActive && <PlaceholderText><B1>Drop the audio file here...</B1></PlaceholderText>}
        {!isDragActive && <PlaceholderText><B1>Drag and drop a file or browse</B1><B1>(.wav, .mp3, .m4a, .avi)</B1></PlaceholderText>}
      </DropFileArea>
    )
  }

  return (
    <DragAndDropWrapper {...getRootProps()} data-testid="dropzone">
      {!props.file && renderDropArea()}
      {props.file && renderFile()}
    </DragAndDropWrapper>
  )
}

export default FileUpload
