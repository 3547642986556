import React, { useState } from 'react'
import axios from 'axios'
import uuid from 'uuid/v4'
import FileUpload from '../FileUpload'
import Modal from '../Modal'
import { IconWithText, P2 } from '../Text/index'
import Icon from '@mdi/react'
import { mdiInformation } from '@mdi/js'
import { colors } from 'shared/colors'
import { useModalContext } from 'shared/context/ModalProvider'
import { useAuthContext } from 'shared/context/AuthProvider'
import { useNotificationContext } from 'shared/context/NotificationProvider'
import { getCognitoId } from 'shared/extractors'
import { API } from 'aws-amplify'
import { log } from 'shared/logger'
import events from 'shared/events'

function UploadAudioModal (props) {
  const [file, setFile] = useState()
  const [uploading, setUploading] = useState(false)
  const { currentUser } = useAuthContext()
  const { closeModal } = useModalContext()
  const { sendNotification, sendSuccessNotification, sendErrorNotification } = useNotificationContext()
  const cognitoId = getCognitoId(currentUser)

  const uploadFile = async () => {
    if (file) {
      const fileType = file.type.split('/')[1]

      const config = {
        headers: {
          'Content-Type': 'application/octet-stream; charset=utf-8'
        }
      }

      const payload = {
        cache_id: `RC${uuid().replace(/-/g, '')}`,
        file_extension: fileType,
        audio_channel_id: 4
      }

      try {
        sendNotification({ title: 'Uploading audio...', position: 'topRight', loading: true })
        setUploading(true)
        const response = await API.post('TALK_IT_API', `/audio?cognito_id=${cognitoId}`, { body: payload })
        const uploadUrl = response.data.upload_urls[0]
        await axios.put(uploadUrl, file, config)
        sendSuccessNotification({ title: 'Audio upload complete' })
        log(events.RECORDING_SUBMITTED)
        closeModal()
      } catch (error) {
        sendErrorNotification({ title: 'Audio upload failed', action: 'retry', onClick: uploadFile, timeout: 0 })
        closeModal()
      }
    }
  }

  const handleUploadError = (errorMessage) => {
    sendErrorNotification({
      title: errorMessage,
      timeout: 3000
    })
  }

  const modalDialogue = {
    primary: {
      onClick: uploadFile,
      disabled: !file,
      children: 'Submit'
    },
    secondary: {
      onClick: closeModal
    }
  }

  if (uploading) {
    return null
  }

  return (
    <Modal dialogue={modalDialogue} onClose={closeModal} title="Upload Audio" {...props}>
      <IconWithText>
        <Icon color={colors.BLUE} path={mdiInformation} size={1}/>
        <P2>We only transcribe monologue audio files. Interviews and multi-person dialogues will not be transcribed.</P2>
      </IconWithText>
      <FileUpload
        acceptedFileTypes="audio/*"
        file={file}
        onError={handleUploadError}
        setFile={setFile}
      />
    </Modal>
  )
}

export default UploadAudioModal
